import React, { useState } from 'react'

import { Switch } from '@te-digi/styleguide'

const SwitchExample1 = () => {
  const [checked, setChecked] = useState(false)

  return (
    <Switch
      checked={checked}
      checkedText="Julkaistaan"
      onChange={setChecked}
      uncheckedText="Ei julkaista"
    />
  )
}

export { SwitchExample1 }
