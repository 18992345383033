import React from 'react'

import { Spacer, Switch } from '@te-digi/styleguide'

const SwitchExample3 = () => (
  <>
    <Spacer marginBottom="md">
      <Switch
        checked
        checkedText="Julkaistaan"
        disabled
        uncheckedText="Ei julkaista"
      />
    </Spacer>
    <Switch
      checkedText="Julkaistaan"
      disabled
      uncheckedText="Ei julkaista"
    />
  </>
)

export { SwitchExample3 }
