import React from 'react'

import { List, ListItem, Paragraph, Switch } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SwitchExample1 } from '../../examples/react/SwitchExample1'
import { SwitchExample2 } from '../../examples/react/SwitchExample2'
import { SwitchExample3 } from '../../examples/react/SwitchExample3'
import { Code } from '../../components/Code'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Switch"
    components={[{ component: Switch }]}
    status={[{ type: 'accessible', version: '15.3.0' }]}
  >
    <Section>
      <Paragraph>
        Käytä valintakytkintä harkiten. Valintakytkintä ei pidä käyttää
        korvaamaan <Code>Checkbox</Code>-komponenttia.
      </Paragraph>

      <Playground example={SwitchExample1} />
      <Playground example={SwitchExample3} />
      <Playground example={SwitchExample2} />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Käytä komponenttia harkiten ja vain tilanteissa, joissa on selkeästi
          kaksi valintaa.
        </ListItem>
        <ListItem>
          Switchin valintojen pitää olla selkeästi kuvatut ja liittyä siihen,
          mitä valinnasta tapahtuu.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
